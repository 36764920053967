import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import dayjs from 'dayjs';

const OptOutCheckbox = ({
  optOutChallenge = false,
  optOutChallengeDate = null,
  daysFromOptOut = 0,
  handleCheckboxChange = () => {},
}) => (
  <>
    <Typography
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        fontSize: '24px',
        fontWeight: 600,
        mb: 2,
        width: '100%',
      }}
    >
      Opt Out of Challenge
    </Typography>
    <FormControlLabel
      control={(
        <Checkbox
          name="Sort"
          value={optOutChallenge}
          checked={optOutChallenge}
          onChange={(_, v) => handleCheckboxChange(v)}
          sx={{ color: 'white', fontSize: '12px !important' }}
          disabled={optOutChallenge}
        />
      )}
      label={(
        <div className="flex">
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 500,
              letterSpacing: '1.2px',
            }}
          >
            Opt Out Challenge
          </Typography>
        </div>
      )}
    />
    {optOutChallenge && optOutChallengeDate && (
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          fontSize: '16px',
          mb: 2,
          width: '100%',
        }}
      >
        {`Opted out on ${dayjs(optOutChallengeDate).format('MMM DD, YYYY')} ${
          daysFromOptOut > 1 ? `(${daysFromOptOut} days ago)` : ''
        }`}
      </Typography>
    )}
  </>
);

export default OptOutCheckbox;
