import { Checkbox, FormControlLabel, Typography } from '@mui/material';

const OptInCheckbox = ({
  handleOptInCheckboxChange = () => {},
}) => (
  <FormControlLabel
    control={(
      <Checkbox
        name="OptIn"
        value={false}
        checked={false}
        onChange={(_, v) => {
          handleOptInCheckboxChange(v);
        }}
        sx={{ color: 'white', fontSize: '12px !important' }}
      />
    )}
    label={(
      <div className="flex">
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 500,
            letterSpacing: '1.2px',
          }}
        >
          Opt Back In Challenge
        </Typography>
      </div>
    )}
  />
);

export default OptInCheckbox;
