import { Grid } from '@mui/material';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

const ConfirmOptInModal = ({
  openOptInModal = false,
  setOpenOptInModal = () => {},
  handleConfirmOptInModalSubmit = () => {},
}) => (
  <Modal
    title="Opt In to Challenge"
    open={openOptInModal}
    handleClose={() => setOpenOptInModal(false)}
    width="650px"
  >
    <Grid container mb={4} justifyContent="center" textAlign="center">
      <span className="primary-text-gradient" style={{ fontSize: '20px' }}>
        Are you sure?
      </span>
      <p style={{
        fontSize: '16px', marginTop: '16px', display: 'block', width: '100%', 
      }}
      >
        You are opting back into the challenge.
      </p>
    </Grid>
    <Grid
      container
      spacing={2}
      sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
    >
      <Grid item xs={12} md={5}>
        <Button onClick={() => setOpenOptInModal(false)} bordered>
          No
        </Button>
      </Grid>
      <Grid item xs={12} md={7}>
        <Button onClick={handleConfirmOptInModalSubmit}>
          Yes
        </Button>
      </Grid>
    </Grid>
  </Modal>
);

export default ConfirmOptInModal;
