import { Grid } from '@mui/material';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

const ConfirmOptOutModal = ({
  openOptOutModal = false,
  setOpenOptOutModal = () => {},
  handleConfirmOptOutModalSubmit = () => {},
}) => (
  <Modal
    title="Opt Out of Challenge"
    open={openOptOutModal}
    handleClose={() => setOpenOptOutModal(false)}
    width="650px"
  >
    <Grid container mb={4} justifyContent="center" textAlign="center">
      <span className="primary-text-gradient" style={{ fontSize: '20px' }}>
        Are you sure?
      </span>
      <p style={{ fontSize: '16px', marginTop: '16px' }}>
        By choosing this opt-out, after 30 days you will be opted out of the
        challenge. You will continue to gain auto-upgrade ranks as funds are
        available during the 30 day opt out period. You will then be eligible
        for a buyback request if you wish to do so. After you have been removed 
        from the challenge, you will be entered into the legacy program and 
        will be eligible for a buyback.
      </p>
    </Grid>
    <Grid
      container
      spacing={2}
      sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
    >
      <Grid item xs={12} md={5}>
        <Button onClick={() => setOpenOptOutModal(false)} bordered>
          No
        </Button>
      </Grid>
      <Grid item xs={12} md={7}>
        <Button onClick={handleConfirmOptOutModalSubmit}>
          Yes
        </Button>
      </Grid>
    </Grid>
  </Modal>
);

export default ConfirmOptOutModal;
