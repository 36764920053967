import { useState } from 'react';
import {
  Checkbox, FormControlLabel, Grid, Typography, 
} from '@mui/material';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

const ConfirmOptBuyBackModal = ({
  openOptBuyBackModal = false,
  setOpenOptBuyBackModal = () => {},
  handleConfirmOptBuyBackModalSubmit = () => {},
}) => (
  <Modal
    title="Are you sure?"
    open={openOptBuyBackModal}
    handleClose={() => setOpenOptBuyBackModal(false)}
    width="650px"
  >
    <Grid container mb={4} justifyContent="center" textAlign="center">
      <span className="primary-text-gradient" style={{ fontSize: '20px' }}>
        Was your initial coin pack purchase made through your sponsor?
      </span>
    </Grid>
    <Grid
      container
      spacing={2}
      sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
    >
      <Grid item xs={12} md={5}>
        <Button
          onClick={() => {
            setOpenOptBuyBackModal(false);
          }}
          bordered
        >
          No
        </Button>
      </Grid>
      <Grid item xs={12} md={7}>
        <Button
          onClick={handleConfirmOptBuyBackModalSubmit}
        >
          Yes
        </Button>
      </Grid>
    </Grid>
  </Modal>
);

export default ConfirmOptBuyBackModal;
