import { Container, Grid } from '@mui/material';
import { useContext } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/CardLayout';
import { Provider, Context } from './context';
import ReferalLink from './components/referalLink';
import ComunityHome from './components/comunityHome';
import SalesTable from './components/salesTable';
import Events from './components/events';
import News from './components/news';
import AskAI from './components/askAI';
import RenewBanner from './components/renewBanner';
import MemecoinVideo from './components/memecoinVideo';
import MemeCoinsPool from './components/memecoins';
import TeamsCards from '../../components/GenealogyTeamCards';
import ActivatedPassesModal from './components/activatedPassesModal';
import GlobalBanners from '../../components/GlobalBanners';
import ActiveChallengePack from './components/ActiveChallengePack';
import PapActiveMessage from './components/papActiveMessage';

export function Home() {
  const {
    user,
    showProduct,
    volumes,
    personalVolume,
    gamersVolume,
    qualificationPercent,
    activatedPassesToDisplay,
    totalChallengePacks,
    challengeVolume,
  } = useContext(Context);
  const navigate = useNavigate();

  if (!user) {
    navigate('/landing');
  }

  const canView = user?.Permissions?.IsAdmin || user?.isAffiliate;
  const canView2 = !(user.isAffiliate && !user.isProduct);
  const showBanner = user.isProduct && user.isAffiliate && !user.Monetized && moment().tz('GMT').startOf('day').isAfter(user.DateExpiring);
  const canViewGame = user?.isProduct && !showBanner;

  return (
    <Container sx={{ mt: !canView2 ? '40px' : '' }} maxWidth="xl">
      <GlobalBanners />
      {
        activatedPassesToDisplay ? <ActivatedPassesModal data={activatedPassesToDisplay} /> : ''
      }

      {showBanner && (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ mb: 4 }}>
          <RenewBanner />
        </Grid>
      )}

      {/* <PapActiveMessage user={user} /> */}

      <Grid item xs={12} sx={!showBanner ? { my: 4 } : { mb: 4 }}>
        <ActiveChallengePack />
      </Grid>

      {canViewGame && (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ mb: 4 }}>
          <MemeCoinsPool />
        </Grid>
      )}

      <Grid spacing={4} container className="home">

        {showProduct && (
          <>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container spacing={4}>
              {
                (canView2 && !showBanner) && (
                  <Grid item>
                    <ComunityHome />
                  </Grid>
                )
              }
              {
                (canView2 && !showBanner) && (
                  <Grid item>
                    <News />
                  </Grid>
                )
              }
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container spacing={4}>
              {
                (canView2 && !showBanner) && (
                  <Grid item>
                    <Events />
                  </Grid>
                )
              }
              {
                (canView2 && !showBanner) && (
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <MemecoinVideo />
                  </Grid>
                )
              }

              {
                (canView2 && !showBanner) && (
                  <Grid item className="ask-ai-grid">
                    <AskAI />
                  </Grid>
                )
              }
            </Grid>
          </>
        )}

        {
          canView && (
            <>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Layout sx={{ maxHeight: '100%' }} maxWidth="100%">
                  <Grid spacing={3} container>
                    <Grid item sx={{}} xl={12} lg={12} md={12} sm={12} xs={12}>
                      <ReferalLink />
                    </Grid>
                    {/* <Grid sx={{}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Volume />
                    </Grid> */}

                    {Array.isArray(volumes) && volumes[0] ? (
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <TeamsCards
                          user={user}
                          volume={volumes[0]}
                          noContainer
                          personalVolume={personalVolume}
                          challengeVolume={challengeVolume}
                          totalChallengePacks={totalChallengePacks}
                          qualificationPercent={qualificationPercent}
                          cardClassName="dark"
                          gamersVolume={gamersVolume}
                        />
                      </Grid>
                    ) : null}

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <SalesTable />
                    </Grid>

                  </Grid>
                </Layout>
              </Grid>

              {/* <Grid item xl={12} lg={12} md={12} sm={12}>
                <Wallets />
              </Grid> */}

              {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <AffiliateUpdates />
              </Grid> */}
            </>
          )
        }

      </Grid>
    </Container>
  );
}

export default () => (
  <Provider>
    <Home />
  </Provider>
);
