import { toast } from 'react-toastify';
import {
  useCallback, useContext, useState, 
} from 'react';
import dayjs from 'dayjs';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import ConfirmOptOutModal from './ConfirmOptOutModal';
import ConfirmOptInModal from './ConfirmOptInModal';
import ConfirmOptBuyBackModal from './ConfirmOptBuyBackModal';
import OptOutCheckbox from './OptOutCheckbox';
import OptInCheckbox from './OptInCheckbox';
import OptBuyBackCheckbox from './OptBuyBackCheckbox';

const OptOutChallenge = () => {
  const { user } = useContext(AuthenticationContext);
  const { authPut } = useApi();
  const signUpLate = dayjs(user.createdAt).isAfter(dayjs('2025-03-08'));

  const [optOutChallenge, setOptOutChallenge] = useState(!!user.optOutChallenge);
  const [optOutChallengeDate, setOptOutChallengeDate] = useState(user.optOutChallengeDate || null);
  const [daysFromOptOut, setDaysFromOptOut] = useState(
    user.optOutChallengeDate ? dayjs().diff(dayjs(user.optOutChallengeDate), 'days') : 0,
  );
  const [openOptOutModal, setOpenOptOutModal] = useState(false);
  const [openOptInModal, setOpenOptInModal] = useState(false);
  const [openOptBuyBackModal, setOpenOptBuyBackModal] = useState(false);

  const handleCheckboxChange = (newVal) => {
    if (!newVal) {
      toast.error('You can\'t change this value.');
      return;
    }
    setOpenOptOutModal(newVal);
  };

  const handleOptInCheckboxChange = (newVal) => {
    setOpenOptInModal(newVal);
  };

  const handleOptBuyBackCheckboxChange = (newVal) => {
    setOpenOptBuyBackModal(newVal);
  };

  const handleConfirmOptOutModalSubmit = useCallback(
    async () => {
      try {
        await authPut('/profile/opt-out-challenge', { data: { optOutChallenge: true } });
        setOpenOptOutModal(false);
        setOptOutChallenge(true);
        setOptOutChallengeDate(new Date());
        setDaysFromOptOut(0);
        toast.success('Updated successfully');
      } catch (err) {
        toast.error('Error updating Profile');
      }
    },
    [authPut, setOptOutChallenge],
  );

  const handleConfirmOptInModalSubmit = useCallback(
    async () => {
      try {
        await authPut('/profile/opt-in-challenge', { 
          data: {
            optInChallenge: true,
          }, 
        });
        setOptOutChallenge(false);
        setOpenOptInModal(false);
        setOptOutChallengeDate(null);
        setDaysFromOptOut(0);
        toast.success('Thank you! You have opted back in to the challenge and no further action is required on your part');
      } catch (err) {
        toast.error('Error updating Profile');
      }
    },
    [authPut],
  );

  const handleConfirmOptBuyBackModalSubmit = useCallback(
    async () => {
      try {
        await authPut('/profile/opt-buy-back-challenge', { 
          data: {
            sponsorPurchase: true,
          }, 
        });
        setOptOutChallenge(false);
        setOpenOptBuyBackModal(false);
        setOptOutChallengeDate(null);
        setDaysFromOptOut(0);
        toast.success('Thank you, we will evaluate your request and begin a review within 72 hours.');
      } catch (err) {
        toast.error('Error updating Profile');
      }
    },
    [authPut],
  );

  if (signUpLate) return null;

  if (!('optOutChallengeDate' in user)) return null;

  return (
    <>
      <OptOutCheckbox
        optOutChallenge={optOutChallenge}
        optOutChallengeDate={optOutChallengeDate}
        daysFromOptOut={daysFromOptOut}
        handleCheckboxChange={handleCheckboxChange}
      />

      {optOutChallenge && daysFromOptOut >= 30 && (
        <>
          <OptInCheckbox
            daysFromOptOut={daysFromOptOut}
            handleOptInCheckboxChange={handleOptInCheckboxChange}
          />

          <OptBuyBackCheckbox
            handleOptBuyBackCheckboxChange={handleOptBuyBackCheckboxChange}
          />
        </>
      )}

      <ConfirmOptOutModal
        openOptOutModal={openOptOutModal}
        setOpenOptOutModal={setOpenOptOutModal}
        handleConfirmOptOutModalSubmit={handleConfirmOptOutModalSubmit}
      />

      <ConfirmOptInModal
        openOptInModal={openOptInModal}
        setOpenOptInModal={setOpenOptInModal}
        handleConfirmOptInModalSubmit={handleConfirmOptInModalSubmit}
      />

      <ConfirmOptBuyBackModal
        openOptBuyBackModal={openOptBuyBackModal}
        setOpenOptBuyBackModal={setOpenOptBuyBackModal}
        handleConfirmOptBuyBackModalSubmit={handleConfirmOptBuyBackModalSubmit}
      />
    </>
  );
};

export default OptOutChallenge;
