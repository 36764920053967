/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext, useState } from 'react';
import clsx from 'clsx';
import { Typography, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { renderNumber } from '../../../../../utils/string';
import { Context } from '../../../context';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import PurchasePasses from '../../../../Memberships/components/PurchasePasses';
import PurchasePackages from '../../../../Memberships/components/PurchasePackages';
import { tabTypes } from '../../../context/helper';
import ClockTimer from '../../../../../components/ClockTimer';
import useScreenSize from '../../../../../utils/hooks/useScreenSize';

export default function Header() {
  const { t } = useTranslation();
  const {
    gamingFundsLoading, gamingFunds, tokens,
    activeTab, setActiveTab,
  } = useContext(Context);
  const [openModal, setOpenModal] = useState(false);
  const { isMobile } = useScreenSize();

  return (
    <div className="game-header flex flex-wrap gap-1">
      <div className="flex flex-1">
        <div className="tabs">
          <div
            onClick={() => setActiveTab(tabTypes.TODAY)}
            className={clsx('tab', activeTab === tabTypes.TODAY && 'active')}
          >
            <span>{t("Today's Matchup")}</span>
          </div>
          <div
            onClick={() => setActiveTab(tabTypes.YESTERDAY)}
            className={clsx(
              'tab',
              activeTab === tabTypes.YESTERDAY && 'active',
            )}
          >
            <span>{t("Yesterday's Closeout")}</span>
          </div>
        </div>

        {!isMobile && (
          <Grid
            item
            alignItems="center"
            paddingLeft="32px"
            flexGrow="1"
            display="flex"
          >
            <ClockTimer prefix="Game Time (GMT) " />
          </Grid>
        )}
      </div>

      <Box
        sx={{
          justifyContent: {
            xs: 'center',
            xl: 'end',
            lg: 'end',
            md: 'end',
            sm: 'center',
          },
        }}
        className="flex gap-1 flex-wrap "
      >
        <Typography component="h2">
          MG Coins
          {' '}
          <span className="primary-text-gradient">
            {gamingFundsLoading
              ? 'Loading...'
              : renderNumber(gamingFunds)?.toLocaleString('en-us')}
          </span>
        </Typography>

        <Typography component="h2">
          MG Tokens
          {' '}
          <span className="primary-text-gradient">
            {renderNumber(tokens || 0)?.toLocaleString('en-us')}
          </span>
        </Typography>

        {/* <Button size="sm" onClick={() => setOpenModal(true)}>
          {t('Purchase more coins')}
        </Button> */}

        {isMobile && (
          <Grid
            item
            alignItems="center"
            flexGrow="1"
            display="flex"
            justifyContent="center"
            width="100%"
          >
            <ClockTimer prefix="Game Time (GMT) " />
          </Grid>
        )}
      </Box>

      <Modal
        open={openModal}
        width="500px"
        title="Purchase MG Coins"
        handleClose={() => {
          setOpenModal(false);
        }}
      >
        <Grid item xs={12}>
          <PurchasePasses />
        </Grid>

        <Grid item xs={12}>
          <PurchasePackages />
        </Grid>
      </Modal>
    </div>
  );
}
