import { Checkbox, FormControlLabel, Typography } from '@mui/material';

const OptBuyBackCheckbox = ({
  handleOptBuyBackCheckboxChange = () => {},
}) => (
  <FormControlLabel
    control={(
      <Checkbox
        name="OptBuyBack"
        value={false}
        checked={false}
        onChange={(_, v) => {
          handleOptBuyBackCheckboxChange(v);
        }}
        sx={{ color: 'white', fontSize: '12px !important' }}
      />
    )}
    label={(
      <div className="flex">
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 500,
            letterSpacing: '1.2px',
          }}
        >
          Buy Back
        </Typography>
      </div>
    )}
  />
);

export default OptBuyBackCheckbox;
