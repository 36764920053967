import dayjs from 'dayjs';
import { Typography, Tooltip } from '@mui/material';
import Button from '../../../../components/Button';

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: () => 'first-column',
  renderCell: ({ value }) => value,
};

export const columnsConfig = (t, submitRenew, onTransferPasses, onTablePassClick) => [
  {
    field: 'action',
    headerName: '',
    minWidth: 120,
    ...defaultConfig,
    renderCell: ({ row }) => {
      if (row.Used) {
        return '';
      }

      return (
        <div className="flex">
          {/* {!row.passType.preventActivateFromCoinPacks && (
            <Button onClick={() => submitRenew({ pass: row.key || row.Token, type: row.Type })} size="sm" className="mr-2 all-upper">
              Activate
            </Button>
          )} */}
          {row?.Transferable ? (
            <Button bordered onClick={() => onTransferPasses({ pass: row.key || row.Token, type: row.Type, price: row.passType.price })} size="sm" className="all-upper">
              Transfer
            </Button>
          ) : null}
        </div>
      );
    },
  },
  {
    field: 'paid',
    minWidth: 100,
    flex: 1,
    headerName: t(''),
    ...defaultConfig,
    renderCell: ({ row }) => {
      if (row.paid) {
        return (
          <Typography className="primary-text-gradient" style={{ textAlign: 'center', width: '100%' }}>
            Ready
          </Typography>
        );
      }
      return (
        <Button onClick={() => { onTablePassClick(row); }} size="sm">
          50/50
        </Button>
      );
    },
  },
  {
    field: 'Type',
    minWidth: 300,
    flex: 1,
    headerName: t('Coin Pack'),
    ...defaultConfig,
  },
  {
    field: 'Token',
    headerName: t('Code'),
    minWidth: 220,
    ...defaultConfig,
    renderCell: ({ row }) => (
      <Typography className="primary-text-gradient">
        {row.key || row.Token}
      </Typography>
    ),
  },
  {
    field: 'DateCreated',
    headerName: t('Created'),
    minWidth: 130,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).tz('GMT').format('MMM DD, YYYY'),
  },
  {
    field: 'DateUsed',
    headerName: t('Used'),
    minWidth: 130,
    ...defaultConfig,
    renderCell: ({ value }) => {
      const time = value ? `${dayjs(value).tz('GMT').format('hh:m A')} GMT` : 'Not Used';
      return (
        <Tooltip title={time} arrow placement="top-end">
          <span>{value ? dayjs(value).tz('GMT').format('MMM DD, YYYY') : 'Not Used'}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'expires',
    headerName: t('Expires'),
    minWidth: 130,
    ...defaultConfig,
    renderCell: ({ value }) => {
      const expired = dayjs().tz('GMT').isAfter(dayjs(value).tz('GMT'));
      const time = value ? `${dayjs(value).tz('GMT').format('hh:m A')} GMT` : 'Not Used';
      return (
        <Tooltip title={time} arrow placement="top-end">
          <span style={{ color: `${expired ? '#ff3c3c' : value ? '#40ff3c' : ''}` }}>{value ? dayjs(value).tz('GMT').format('MMM DD, YYYY') : 'Not Used'}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'Used',
    headerName: t('Used For'),
    minWidth: 130,
    ...defaultConfig,
    renderCell: ({ value }) => (value ? 'Activation' : 'Not Used'),
  },
  {
    field: 'Transferable',
    headerName: t('Transferable'),
    minWidth: 130,
    ...defaultConfig,
    renderCell: ({ value }) => (value ? 'Yes' : 'No'),
  },

];

export default columnsConfig;
